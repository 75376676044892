import axios from "axios";
import React, { useEffect, useState } from "react";
import Banner from "../Components/Banner";
import CourseSlider from "../Components/CourseSlider";
import { baseURL } from "../utils/api";

const Course = ({ match, history }) => {
  const [course, setcourse] = useState(false);
  const [hover, sethover] = useState(false);

  useEffect(() => {
    handleGetCourse();
  }, [match?.params?.id]);
  const handleGetCourse = async () => {
    try {
      const res = await axios({
        url: `${baseURL}/course/courseByCategory/${match?.params?.id}`,
        method: "GET"
      });

      console.log("res", res);
      setcourse(res?.data?.course);
    } catch (err) {
      console.log(err);
    }
  };
  const handleMouseEnter = () => {
    sethover(true);
  };
  const handleMouseLeave = () => {
    sethover(false);
  };
  return (
    <>
      <Banner title="Courses" />
      <section className="courses">
        <div className="container">
          <div className="row">
          </div>
      

          {course?.length > 0 && (
            <div className="offset-lg-1 col-lg-10">
              <div className="" id="">
                <div
                  id=""
                  role="tabpanel"
                  aria-labelledby="pills-more-tab"
                  onMouseEnter={handleMouseEnter} // Or onMouseOver
                  onMouseLeave={handleMouseLeave}
                  style={{
                    ...(hover && {
                      transform: `translate(${3}px, ${2}px)`,
                      cursor: "pointer"
                    })
                  }}
                >
                  <CourseSlider course={course} history={history} />
                </div>
              </div>{" "}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Course;
