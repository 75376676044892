import React from 'react' 
import { toast,ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
const Toasty = (toastytype,msg) => {
    return (
        toastytype=='success' ? toast.success:toast.error(msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          
          
          
    )
}

export default Toasty
