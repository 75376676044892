import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CourseSlider from "../Components/CourseSlider";
import Header from "../Components/Header";
import { baseURL } from "../utils/api";

const Home = ({ history }) => {
  const [countdata, setcountdata] = useState();
  const [categories, setcategories] = useState([]);
  const [categoryid, setcategoryid] = useState("");
  const [course, setcourse] = useState("");
  const [hover, sethover] = useState(false);

  useEffect(() => {
    handleGetCountData();
  }, []);

  const handleGetCountData = async () => {
    try {
      const res = await axios({
        url: `${baseURL}/user/getcount`,
        method: "GET"
      });

      console.log("res", res);
      setcountdata(res?.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    handleGetCategories();
  }, []);

  const handleGetCategories = async () => {
    try {
      const res = await axios({
        url: `${baseURL}/category/allCategories`,
        method: "GET"
      });

      console.log("res", res);
      setcategories(res?.data?.allCategories);
    } catch (err) {
      console.log(err);
    }
  };

  const handleGetCourse = async (id) => {
    try {
      const res = await axios({
        url: `${baseURL}/course/courseByCategory/${id}`,
        method: "GET"
      });

      console.log("res", res);
      setcourse(res?.data?.course);
    } catch (err) {
      console.log(err);
    }
  };
  const handleMouseEnter = () => {
    sethover(true);
  };
  const handleMouseLeave = () => {
    sethover(false);
  };
  return (
    <>
      <section className="banner banner-padding">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="banner-heading">
                A Good Education Is a<br />
                Foundation For Better Future
              </h1>
              <p className="banner-p">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod
              </p>
              <Link to="#" className="general-btn d-inline-block">
                Get Started
              </Link>
              <Link to='#'>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={70}
                  height={70}
                  viewBox="0 0 70 70"
                >
                  <g
                    id="Group_10906"
                    data-name="Group 10906"
                    transform="translate(-845 -639)"
                  >
                    <circle
                      id="Ellipse_112"
                      data-name="Ellipse 112"
                      cx={35}
                      cy={35}
                      r={35}
                      transform="translate(845 639)"
                      fill="#00a79d"
                      opacity="0.5"
                    />
                    <path
                      id="Polygon_56"
                      data-name="Polygon 56"
                      d="M8.75,3.168a2,2,0,0,1,3.5,0l7.11,12.865A2,2,0,0,1,17.61,19H3.39a2,2,0,0,1-1.75-2.967Z"
                      transform="translate(893 664) rotate(90)"
                      fill="#fff"
                    />
                  </g>
                </svg>
                <h6>Watch Our Video</h6>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="video-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <video
                className="w-100"
                id="video"
                poster="images/video-poster.png"
                controls
              >
                <source src="images/mov_bbb.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </section>

      <section className="working">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="main-heading">
                Working Process For Join And <br />
                Benefits
              </h2>
              <p className="working-p">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam{" "}
              </p>
            </div>
          </div>
          <div className="row">
            <div
              className="col-lg-4 col-12 w-100 wow fadeInUp mt-lg-0 mt-2"
              data-wow-duration="3s"
            >
              <Link to="#">
                <div className="text-center icons-div">
                  <img src="images/search-icon.png" alt="" loading="lazy" />
                  <h4>Find Course</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut{" "}
                  </p>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-4 d-flex w-100 wow fadeInUp mt-lg-0 mt-2"
              data-wow-duration="3.2s"
            >
              <Link to="#">
                <div className="text-center icons-div">
                  <img src="images/calender.png" alt="" loading="lazy" />
                  <h4>Book Your Seat</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut{" "}
                  </p>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-4 d-flex w-100 wow fadeInUp mt-lg-0 mt-2"
              data-wow-duration="3.4s"
            >
              <Link to="#">
                <div className="text-center icons-div">
                  <img src="images/certificate.png" alt="" loading="lazy" />
                  <h4>Get Certificate</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut{" "}
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="learning">
        <div className="container-fluid">
          <div className="row">
            <div className="offset-xl-2 col-xl-3 position-relative text-xl-left text-center">
              <img
                src="images/learning-1.png"
                alt=""
                className="img-fluid learning-1"
              />
              <img src="images/learning-2.png" alt="" className="learning-2" />
            </div>
            <div className="col-xl-6 mt-xl-0 mt-3">
              <div className="learning-back">
                <img src="images/boxes.png" alt="" className="learning-boxes" />
                <h2 className="main-heading">
                  Learning Experiences Made Easy , Social And Interactive
                </h2>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua.{" "}
                </p>
                <div className="text-md-left text-center">
                  <Link to="#" className="general-btn d-inline-block ">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="Know">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="main-heading">Get to Know Us</h2>
              <p className="working-p">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam{" "}
              </p>
            </div>
          </div>
          <div className="row">
            <div
              className="col-lg-4 wow fadeInUp mt-lg-0 mt-2"
              data-wow-duration="3s"
            >
              <div className="text-center icons-div-2 w-100">
                <h4>{countdata?.course}+</h4>
                <p>Professional Certificates </p>
              </div>
            </div>
            <div
              className="col-lg-4 d-flex wow fadeInUp mt-lg-0 mt-2"
              data-wow-duration="3.2s"
            >
              <div className="text-center icons-div-2 w-100">
                <h4>{countdata?.course}+</h4>
                <p>Courses &amp; Specialization</p>
              </div>
            </div>
            <div
              className="col-lg-4 d-flex wow fadeInUp mt-lg-0 mt-2"
              data-wow-duration="3.2s"
            >
              <div className="text-center icons-div-2 w-100">
                <h4>{countdata?.user}+</h4>
                <p>Active Students</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="course">
        <div className="container-fluid">
          <div className="row">
            <div className="offset-lg-2 col-lg-10">
              <h2 className="main-heading">Get a Course Of Your Choice</h2>
              <p className="coures-p">Please Select A Category</p>
            </div>
            <div className="offset-lg-2 col-xl-7">
              <ul
                className="nav nav-pills home-pills"
                id="pills-tab"
                role="tablist"
              >
                {categories?.length > 0 &&
                  categories?.map((cat) => (
                    <li className="nav-item" role="presentation">
                      <Link
                        to="#"
                        className="nav-link"
                        data-toggle="pill"
                        onClick={() => {
                          setcategoryid(cat?._id);
                          handleGetCourse(cat?._id);
                        }}
                      >
                        {cat?.name}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
            {course?.length > 0 && (
              <div className="offset-lg-1 col-lg-10">
                <div className="" id="">
                  <div
                    id=""
                    role="tabpanel"
                    aria-labelledby="pills-more-tab"
                    onMouseEnter={handleMouseEnter} // Or onMouseOver
                    onMouseLeave={handleMouseLeave}
                    style={{
                      ...(hover && {
                        transform: `translate(${3}px, ${2}px)`,
                        cursor: "pointer"
                      })
                    }}
                  >
                    <CourseSlider course={course} history={history} />
                  </div>
                </div>{" "}
              </div>
            )}
            {/* <div className="offset-lg-2 col-lg-10">
              <div className="" id="">
               
                <div
                  className="tab-pane fade"
                  id=""
                  role="tabpanel"
                  aria-labelledby="pills-more-tab"
                >
                  <div className="owl-carousel owl-theme" id="more-slider">
                    <div className="item">
                      <div className="course-div">
                        <img
                          src="images/explore-1.png"
                          alt=""
                          className="img-fluid course-main"
                          loading="lazy"
                        />
                        <p>
                          Fully Responsive Web Design &amp; Development Online
                          Course
                        </p>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <img
                              src="images/user-1.png"
                              alt=""
                              className="course-1"
                            />
                            <div className="ml-2">
                              <h4>Thomas Smith</h4>
                              <h3>Web Developer</h3>
                            </div>
                          </div>
                          <a href="#_" className="d-inline-block enroll-btn">
                            Enroll
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="course-div">
                        <img
                          src="images/explore-2.png"
                          alt=""
                          className="img-fluid course-main"
                          loading="lazy"
                        />
                        <p>User Experience Design Figma - UI/UX Design</p>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <img
                              src="images/user-1.png"
                              alt=""
                              className="course-1"
                            />
                            <div className="ml-2">
                              <h4>Thomas Smith</h4>
                              <h3>Web Developer</h3>
                            </div>
                          </div>
                          <a href="#_" className="d-inline-block enroll-btn">
                            Enroll
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="course-div">
                        <img
                          src="images/explore-3.png"
                          alt=""
                          className="img-fluid course-main"
                          loading="lazy"
                        />
                        <p>IOS Software Development &amp; Project Management</p>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <img
                              src="images/user-1.png"
                              alt=""
                              className="course-1"
                            />
                            <div className="ml-2">
                              <h4>Thomas Smith</h4>
                              <h3>Web Developer</h3>
                            </div>
                          </div>
                          <a href="#_" className="d-inline-block enroll-btn">
                            Enroll
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="course-div">
                        <img
                          src="images/explore-4.png"
                          alt=""
                          className="img-fluid course-main"
                          loading="lazy"
                        />
                        <p>
                          Fully Responsive Web Design &amp; Development Online
                          Course
                        </p>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <img
                              src="images/user-1.png"
                              alt=""
                              className="course-1"
                            />
                            <div className="ml-2">
                              <h4>Thomas Smith</h4>
                              <h3>Web Developer</h3>
                            </div>
                          </div>
                          <a href="#_" className="d-inline-block enroll-btn">
                            Enroll
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <section className="student">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="main-heading">What Our Students are Saying</h2>
              <p className="student-p">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod{" "}
              </p>
            </div>
            <div className="owl-carousel owl-theme" id="testimonial">
              <div className="item">
                <div className="row">
                  <div className="col-lg-8 p-3 position-relative">
                    <div className="testimonial-para">
                      <p>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy eirmod tempor invidunt ut labore et
                        dolore magna aliquyam erat, sed diam voluptua. At vero
                        eos et accusam et justo duo dolores et ea rebum. Stet
                        clita kasd gubergren, no sea takimata sanctus{" "}
                      </p>
                    </div>
                    <img
                      src="images/testimonial-back.png"
                      alt=""
                      className="textimonial-img"
                    />
                  </div>
                  <div className="col-lg-4 my-auto">
                    <div className="d-flex align-items-center">
                      <img
                        src="images/testimonial-user.png"
                        alt=""
                        className="testimonial-user"
                      />
                      <div className="ml-3">
                        <h2 className="testimonial-h2">Juliana Lora</h2>
                        <h3 className="testimonial-h3">Dept. of Math</h3>
                        <i className="fas fa-star orange-star" />
                        <i className="fas fa-star orange-star" />
                        <i className="fas fa-star orange-star" />
                        <i className="fas fa-star orange-star" />
                        <i className="fas fa-star orange-star" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="row">
                  <div className="col-lg-8 p-3 position-relative">
                    <div className="testimonial-para">
                      <p>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy eirmod tempor invidunt ut labore et
                        dolore magna aliquyam erat, sed diam voluptua. At vero
                        eos et accusam et justo duo dolores et ea rebum. Stet
                        clita kasd gubergren, no sea takimata sanctus{" "}
                      </p>
                    </div>
                    <img
                      src="images/testimonial-back.png"
                      alt=""
                      className="textimonial-img"
                    />
                  </div>
                  <div className="col-lg-4 my-auto">
                    <div className="d-flex align-items-center">
                      <img
                        src="images/testimonial-user.png"
                        alt=""
                        className="testimonial-user"
                      />
                      <div className="ml-3">
                        <h2 className="testimonial-h2">Juliana Lora</h2>
                        <h3 className="testimonial-h3">Dept. of Math</h3>
                        <i className="fas fa-star orange-star" />
                        <i className="fas fa-star orange-star" />
                        <i className="fas fa-star orange-star" />
                        <i className="fas fa-star orange-star" />
                        <i className="fas fa-star orange-star" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="row">
                  <div className="col-lg-8 p-3 position-relative">
                    <div className="testimonial-para">
                      <p>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy eirmod tempor invidunt ut labore et
                        dolore magna aliquyam erat, sed diam voluptua. At vero
                        eos et accusam et justo duo dolores et ea rebum. Stet
                        clita kasd gubergren, no sea takimata sanctus{" "}
                      </p>
                    </div>
                    <img
                      src="images/testimonial-back.png"
                      alt=""
                      className="textimonial-img"
                    />
                  </div>
                  <div className="col-lg-4 my-auto">
                    <div className="d-flex align-items-center">
                      <img
                        src="images/testimonial-user.png"
                        alt=""
                        className="testimonial-user"
                      />
                      <div className="ml-3">
                        <h2 className="testimonial-h2">Juliana Lora</h2>
                        <h3 className="testimonial-h3">Dept. of Math</h3>
                        <i className="fas fa-star orange-star" />
                        <i className="fas fa-star orange-star" />
                        <i className="fas fa-star orange-star" />
                        <i className="fas fa-star orange-star" />
                        <i className="fas fa-star orange-star" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="row">
                  <div className="col-lg-8 p-3 position-relative">
                    <div className="testimonial-para">
                      <p>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy eirmod tempor invidunt ut labore et
                        dolore magna aliquyam erat, sed diam voluptua. At vero
                        eos et accusam et justo duo dolores et ea rebum. Stet
                        clita kasd gubergren, no sea takimata sanctus{" "}
                      </p>
                    </div>
                    <img
                      src="images/testimonial-back.png"
                      alt=""
                      className="textimonial-img"
                    />
                  </div>
                  <div className="col-lg-4 my-auto">
                    <div className="d-flex align-items-center">
                      <img
                        src="images/testimonial-user.png"
                        alt=""
                        className="testimonial-user"
                      />
                      <div className="ml-3">
                        <h2 className="testimonial-h2">Juliana Lora</h2>
                        <h3 className="testimonial-h3">Dept. of Math</h3>
                        <i className="fas fa-star orange-star" />
                        <i className="fas fa-star orange-star" />
                        <i className="fas fa-star orange-star" />
                        <i className="fas fa-star orange-star" />
                        <i className="fas fa-star orange-star" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="explore">
        <div className="container">
          <div className="row wow fadeInDown" data-wow-duration="3s">
            <div className="col-12">
              <h2 className="main-heading">Explore Top Trending Blogs</h2>
              <h2 className="explore-sub">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod
              </h2>
            </div>
          </div>
          <div className="row">
            <div
              className="col-lg-4 mt-lg-0 mt-3 wow fadeInUp"
              data-wow-duration=".8s"
            >
              <Link to="#">
                <div className="explore-div position-relative">
                  <span>
                    <h5 className="f-20 medium lblack">23</h5>
                    <h6 className="f-14 medium lblack mt-1">Jul</h6>
                  </span>
                  <img
                    src="images/explore-1.png"
                    alt=""
                    className="img-fluid explore-main"
                    loading="lazy"
                  />
                  <div className="d-flex align-items-center name-div">
                    <img src="images/user-1.png" alt="" className="user-1" />
                    <div>
                      <h4>Thomas Smith</h4>
                      <h3>Article Writer</h3>
                    </div>
                  </div>
                  <p className="f-18 medium lblack">
                    How To Design a website to follow the rules of UI/UX...
                  </p>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-4 mt-lg-0 mt-3 wow fadeInUp"
              data-wow-duration=".8s"
            >
              <Link to="#">
                <div className="explore-div position-relative">
                  <span>
                    <h5 className="f-20 medium lblack">23</h5>
                    <h6 className="f-14 medium lblack mt-1">Jul</h6>
                  </span>
                  <img
                    src="images/explore-2.png"
                    alt=""
                    className="img-fluid explore-main"
                    loading="lazy"
                  />
                  <div className="d-flex align-items-center name-div">
                    <img src="images/user-1.png" alt="" className="user-1" />
                    <div>
                      <h4>Mark Carson</h4>
                      <h3>Content Writer</h3>
                    </div>
                  </div>
                  <p className="f-18 medium lblack">
                    How to work with Adobe Xd like a Pro.
                  </p>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-4 mt-lg-0 mt-3 wow fadeInUp"
              data-wow-duration=".8s"
            >
              <Link to="#">
                <div className="explore-div position-relative">
                  <span>
                    <h5 className="f-20 medium lblack">23</h5>
                    <h6 className="f-14 medium lblack mt-1">Jul</h6>
                  </span>
                  <img
                    src="images/explore-3.png"
                    alt=""
                    className="img-fluid explore-main"
                    loading="lazy"
                  />
                  <div className="d-flex align-items-center name-div">
                    <img src="images/user-1.png" alt="" className="user-1" />
                    <div>
                      <h4>Anderson Peter</h4>
                      <h3>Article Writer</h3>
                    </div>
                  </div>
                  <p className="f-18 medium lblack">
                    How to be a better learner , all tips &amp; tricks here.
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
