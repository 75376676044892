import axios from "axios";
import React, { useState } from "react";
import Banner from "../Components/Banner";
import { baseURL } from "../utils/api";
import Toasty from "../utils/toast";
import Swal from "sweetalert2";
import { validateEmail } from "../utils/ValidateEmail";

const Contactus = ({ history }) => {
  const [type, settype] = useState("Contact Us form");
  const [fullname, setfullname] = useState("");
  const [email, setemail] = useState("");
  const [subject, setsubject] = useState("");
  const [message, setmessage] = useState("");
  const submitHandler = async () => {
    console.log("submitHandler");
    const emailvalidation = validateEmail(email);
    console.log("emmmm", emailvalidation);
    console.log("addEmployeeHandler");
    if (emailvalidation == true) {
    const res = await axios.post(
      `${baseURL}/feedback/unregisteredcreateFeedback`,
      {
        fullname: fullname,
        email: email,
        subject: subject,
        message: message,
        type: type,
      }
    );
    console.log("res", res);
    Swal.fire({
      icon: "success",
      title: "",
      text: `Form Sent Successfully`,
      showConfirmButton: false,
      timer: 1500,
    });
    history?.push("/");}else {
      Toasty("error", `Please enter a valid email`);
    }
  };
  return (
    <>
      <Banner />
      <section className="working pb-0 contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <h2 className="main-heading">Contact Us</h2>
              <p className="contact-p">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam{" "}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <form >
                <label htmlFor className="input-label">
                  Full Name
                </label>
                <input
                  type="text"
                  className="form-control w-90"
                  placeholder="Enter Full Name"
                  style={{color:'black !important'}}
                  value={fullname}
                  onChange={(e) => {
                    setfullname(e.target.value);
                  }}
                />
                <label htmlFor className="input-label mt-4">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control w-90"
                  placeholder="Enter Email Address"
                  value={email}
                  onChange={(e) => {
                    setemail(e.target.value);
                  }}
                />
                <label htmlFor className="input-label mt-4">
                  Subject
                </label>
                <input
                  type="text"
                  className="form-control w-90"
                  placeholder="Enter Subject"
                  value={subject}
                  onChange={(e) => {
                    setsubject(e.target.value);
                  }}
                />
                <label htmlFor className="input-label mt-4">
                  Description
                </label>
                <textarea
                  rows={5}
                  placeholder="Enter Description"
                  className="form-control w-90"
                  value={message}
                  onChange={(e) => {
                    setmessage(e.target.value);
                  }}
                />
                <div className="text-center mt-4">
                  <button
                  type="button"
                    onClick={() =>
                      email?.length > 0 &&
                      message?.length > 0 &&
                      fullname?.length > 0 &&
                      subject?.length > 0
                        ? submitHandler()
                        : Toasty(
                            "error",
                            `Please fill out all the required fields`
                          )
                    }
                    className="signup-login-btn w-100"
                  >
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contactus;
