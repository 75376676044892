import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CourseSlider from "../Components/CourseSlider";
import Header from "../Components/Header";
import { baseURL } from "../utils/api";

const Home = ({ history }) => {
  const [countdata, setcountdata] = useState();
  const [categories, setcategories] = useState([]);
  const [categoryid, setcategoryid] = useState("");
  const [course, setcourse] = useState("");
  const [hover, sethover] = useState(false);

  useEffect(() => {
    handleGetCountData();
  }, []);

  const handleGetCountData = async () => {
    try {
      const res = await axios({
        url: `${baseURL}/user/getcount`,
        method: "GET"
      });

      console.log("res", res);
      setcountdata(res?.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    handleGetCategories();
  }, []);

  const handleGetCategories = async () => {
    try {
      const res = await axios({
        url: `${baseURL}/category/allCategories`,
        method: "GET"
      });

      console.log("res", res);
      setcategories(res?.data?.allCategories);
    } catch (err) {
      console.log(err);
    }
  };

  const handleGetCourse = async (id) => {
    try {
      const res = await axios({
        url: `${baseURL}/course/courseByCategory/${id}`,
        method: "GET"
      });

      console.log("res", res);
      setcourse(res?.data?.course);
    } catch (err) {
      console.log(err);
    }
  };
  const handleMouseEnter = () => {
    sethover(true);
  };
  const handleMouseLeave = () => {
    sethover(false);
  };
  return (
    <>
      <section className="banner " style={{height:"100vh",display:"flex",alignItems:"center",justifyContent:"center"}}>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
            <img src="images/logo.png" alt="" loading="lazy" />
              <h1 className="banner-heading2">
                Coming Soon
              </h1>
              <p className="banner-p">
              We're thrilled to announce that our website is undergoing a transformation. We're working tirelessly to bring you an enhanced online experience.
              </p>
            
            </div>
          </div>
        </div>
      </section>


    </>
  );
};

export default Home;
