import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import About from "./Screens/About";
import Home from "./Screens/Home";
import Course from "./Screens/Course";
import CourseDetails from "./Screens/CourseDetails";
import Contactus from "./Screens/Contactus";
import AllofCourses from "./Screens/AllofCourses";
import Coming from "./Screens/Coming"

const App = () => {
  return (
    <Router basename="/">
      <Header/>
      <Route path="/" component={Coming} exact />{" "}
      <Route path="/home" component={Home} exact />{" "}
      <Route path="/About" component={About} exact />{" "}
      <Route path="/Course/:id" component={Course} exact />{" "}
      <Route path="/details-course:id" component={CourseDetails} exact />{" "}
      <Route path="/Contactus" component={Contactus} exact />{" "}
      <Route path="/allofcourses" component={AllofCourses} exact />{" "}

      <Footer/>
    </Router>
  );
};

export default App;
