import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Banner from "../Components/Banner";
import { baseURL } from "../utils/api";

const CourseDetails = ({ match }) => {
  const [course, setcourse] = useState();
  useEffect(() => {
    handleGetCourse();
  }, []);

  const handleGetCourse = async () => {
    try {
      const res = await axios({
        url: `${baseURL}/course/courseDetails/${match?.params?.id}`,
        method: "GET",
      });

      console.log("res", res);
      setcourse(res?.data?.course);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
    <Banner title="Course"/>
      <section className="courses">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-3">
              <Link to="/">
                <h2 className="course-heading-2">
                  <i className="fas fa-angle-left mr-2" />
                  Course Details
                </h2>
                <p className="details-p">{course?.coursedescription}</p>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h3 className="course-heading-3">Course Details</h3>
            </div>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-6 mt-2">
                  <label htmlFor className="course-label">
                    Course Title:
                  </label>
                  <p htmlFor className="d-inline-block course-label">
                    {course?.coursetitle}{" "}
                  </p>
                </div>
                <div className="col-lg-6 mt-2">
                  <label htmlFor className="course-label">
                    Category:
                  </label>
                  <p htmlFor className="d-inline-block course-label">
                    {course?.coursecategory?.name}{" "}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 mt-2">
                  <label htmlFor className="d-inline-block course-label">
                    Duration:
                  </label>
                  <label htmlFor className="d-inline-block course-label">
                    Cost:
                  </label>
                  <br />
                  {course?.courseduration?.length > 0 &&
                    course?.courseduration?.map((course, index) => (
                      <>
                        <p htmlFor className="d-inline-block course-label">
                          {course?.month} month
                        </p>
                        <p htmlFor className="d-inline-block course-label">
                          ${course?.amount}
                        </p>
                      </>
                    ))}
                </div>
                <div className="col-lg-6 mt-2"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h3 className="course-heading-3">Description</h3>
              <p className="details-p">{course?.coursedescription}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h3 className="course-heading-3">Feature of Course</h3>
              <p className="details-p">{course?.coursefeature}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center mt-5">
              <Link
                onClick={() => {
                  window.open(
                    "https://wrightcoacademy.com/user/"
                  );
                }}
                to="#"
                className="signup-login-btn d-inline-block"
                data-toggle="modal"
                data-target="#signup-modal"
              >
                Register Now
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CourseDetails;
