import React from "react";

const Banner = ({title}) => {
  return (
    <section className="banner inner-padding">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="banner-heading">{title ? title : "Contact Us"}</h1>
            <p className="banner-p">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
