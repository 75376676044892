import React from "react";
import { Link } from "react-router-dom";
import { imageURL } from "../utils/api";
import "./test.css";

export default function Search({
  searchstring,
  searchHandelr,
  searchedResult
}) {
  return (
    <form className="_2M8cLY header-form-search">
      <div className="col-12-12 _2oO9oE">
        <div className="_3OO5Xc">
          <input
            className="_3704LK"
            type="text"
            value={searchstring}
            onChange={(e) => {
              searchHandelr(e.target.value);
              // setsearchstring(e.target.value);
            }}
            title="Search for products, brands and more"
            name="searchstring"
            autoComplete="off"
            placeholder="Search for courses"
            defaultValue
          />
        </div>
        <button className="L0Z3Pu" type="button">
          <svg
            width={20}
            height={20}
            viewBox="0 0 17 18"
            className
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="#2874F1" fillRule="evenodd">
              <path
                className="_34RNph"
                d="m11.618 9.897l4.225 4.212c.092.092.101.232.02.313l-1.465 1.46c-.081.081-.221.072-.314-.02l-4.216-4.203"
              />
              <path
                className="_34RNph"
                d="m6.486 10.901c-2.42 0-4.381-1.956-4.381-4.368 0-2.413 1.961-4.369 4.381-4.369 2.42 0 4.381 1.956 4.381 4.369 0 2.413-1.961 4.368-4.381 4.368m0-10.835c-3.582 0-6.486 2.895-6.486 6.467 0 3.572 2.904 6.467 6.486 6.467 3.582 0 6.486-2.895 6.486-6.467 0-3.572-2.904-6.467-6.486-6.467"
              />
            </g>
          </svg>
        </button>
        <input type="hidden" name="otracker" defaultValue="search" />
        <input type="hidden" name="otracker1" defaultValue="search" />
        <input type="hidden" name="marketplace" defaultValue="FLIPKART" />
        <input type="hidden" name="as-show" defaultValue="on" />
        <input type="hidden" name="as" defaultValue="off" />
      </div>
      <ul className="col-12-12 _1MRYA1" style={{}}>
        {/* <li className="Y5N33s">
          <div data-tkid="64370939-633f-4f03-8c26-bb6a0097f72d.mobiles|Mobiles">
            <div className>
              <div className="_38MG2r _3VZV9N">
                <div className="_3VZV9N">Discover More</div>
              </div>
            </div>
          </div>
        </li> */}
        {searchedResult?.length > 0 &&
          searchedResult?.map((cour) => (
            <li className="Y5N33s">
              <div data-tkid="5d49aba6-3522-4893-a046-62a1c67cc76a.mobiles">
                <Link
                  to={`/Course${cour?.coursecategory}`}
                  onClick={() => {
                    searchHandelr("");
                  }}
                  className="_3izBDY _3BfqWP"
                  //   href="/search?q=mobiles&as=on&as-show=on&otracker=AS_Query_TrendingAutoSuggest_1_0_na_na_na&otracker1=AS_Query_TrendingAutoSuggest_1_0_na_na_na&as-pos=1&as-type=TRENDING&suggestionId=mobiles&requestId=5d49aba6-3522-4893-a046-62a1c67cc76a"
                >
                  <div className="wXpsjT">
                    <div
                      className="CXW8mj"
                      style={{ height: "32px", width: "32px" }}
                    >
                      <img
                        className="_396cs4 _1uwInh  _3exPp9"
                        alt=""
                        src={
                          cour?.images?.length > 0 &&
                          `${imageURL}${cour?.images[0]}`
                        }
                      />
                    </div>
                  </div>
                  <div className="lrtEPN"> {cour?.coursetitle}</div>
                </Link>
              </div>
            </li>
          ))}
      </ul>
    </form>
  );
}
