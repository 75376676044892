import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ToastContainer } from "react-toastify";
import HttpsRedirect from "react-https-redirect";

ReactDOM.render(
  <React.StrictMode>
      <HttpsRedirect>
      <ToastContainer />

    <App />
    </HttpsRedirect>
  </React.StrictMode>,
  document.getElementById('root')
);


