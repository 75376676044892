import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { baseURL } from "../utils/api";
import Search from "./Search";

const Header = () => {
  const [categories, setcategories] = useState([]);
  const [searchstring, setsearchstring] = useState("");
  const [searchedResult, setsearchedResult] = useState([]);

  useEffect(() => {
    handleGetCategories();
  }, []);

  const handleGetCategories = async () => {
    try {
      const res = await axios({
        url: `${baseURL}/category/allCategories`,
        method: "GET"
      });

      console.log("res", res);
      setcategories(res?.data?.allCategories);
    } catch (err) {
      console.log(err);
    }
  };
  const searchHandelr = async (value) => {
    setsearchstring(value);
    console.log("searchHandelr",value);
    if(value==''){setsearchedResult([])}
    else{
    try {
      const res = await axios.post(`${baseURL}/course/filterCoursebyText`, {
        searchString: value
      });
      setsearchedResult(res?.data?.filteredCourse);
      console.log("resssssssss", res);
    } catch (error) {}}
  };
  console.log("::::",window.location.pathname)
  if(window.location.pathname == "/") {
    return <></>;
  }else{
    return (
      <>
        <section className="nav-section">
          <div className="container-fluid">
          <div className="row justify-content-center">
              <div className="col-10">
            <div className="row">
              <div className="col-12">
                <nav className="navbar navbar-expand-lg navbar-light custom-nav">
                  <Link className="navbar-brand" to="/">
                    <img src="images/logo.png" alt="" loading="lazy" />
                  </Link>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <i className="fas fa-bars" />
                  </button>
                  <div className="collapse navbar-collapse" id="navbarNav" >
                    <ul className="navbar-nav mx-auto center-ul" >
                      <li className="nav-item ">
                        <Link to="/home" className="nav-link">
                          Home
                        </Link>
                      </li>
                      <li className="nav-item active">
                        <Link to="/About" className="nav-link">
                          About Us
                        </Link>
                      </li>
                      <li className="nav-item dropdown ">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Courses
                        </a>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          {categories?.length > 0 &&
                            categories?.map((cat) => (
                              <Link
                                to={`/Course/${cat?._id}`}
                                className="dropdown-item"
                              >
                                {cat?.name}
                              </Link>
                            ))}
                        </div>
                      </li>
                      <li className="nav-item ">
                        <Link to="/Contactus" className="nav-link">
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                    <ul className="navbar-nav ml-auto headerSearch">
                      <Search
                        searchstring={searchstring}
                        searchHandelr={searchHandelr}
                        searchedResult={searchedResult}
                      
                      />{" "}
                      <li className="nav-item mt-lg-0 mt-2">
                        <Link
                          onClick={() => {
                            window.open(
                              "https://wrightcoacademy.com/user/"
                            );
                          }}
                          to="#"
                          className="nav-link signup-login-btn"
                        >
                          Signup / Login
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
          </div>
          </div>
        </section>
      </>
    );
  }

};

export default Header;
