import React, { Component } from "react";
import { Link, useHistory } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { imageURL } from "../utils/api";

export default class CourseSlider extends Component {
    
    render() {
        console.log("this.props", this.props);
      const settings = {
          dots: true,
          infinite: false,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
        };
        return (
      <Slider {...settings}>
        {this?.props?.course?.map((cour) => (
          <div className="item"
          onClick={()=>{
            this?.props?.history?.push(`/details-course${cour?._id}`)
          }}>
            <div className="course-div" style={{ marginLeft: 15 }}>
              <img
                src={`${imageURL}${cour?.images?.length>0&& cour?.images[0]}`}
                alt=""
                className="img-fluid course-main"
                loading="lazy"
              />
              <div style={{height:15}}></div>
              <p>{cour?.coursetitle}</p>
              
              {/* <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <img src="images/user-1.png" alt="" className="course-1" />
                  <div className="ml-2">
                    <h4>Thomas Smith</h4>
                    <h3>Web Developer</h3>
                  </div>
                </div>
                <a href="#_" className="d-inline-block enroll-btn">
                  Enroll
                </a>
              </div> */}
            </div>
          </div>
        ))}
      </Slider>
    );
  }
}
