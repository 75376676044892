import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  if(window.location.pathname == "/") {
    return <></>;
  }else{
  return (
    <>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-lg-flex justify-content-between align-items-center join text-lg-left text-center">
                <div>
                  <h2>Ready To Join?</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod
                  </p>
                </div>
                <Link
                  onClick={() => {
                    window.open(
                      "https://wrightcoacademy.com/user/"
                    );
                  }}
                  to="#"
                  className="general-btn d-inline-block mt-lg-0 mt-3"
                >
                  Register Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div className="footer-top">
          <div className="container">
            <div className="row mt-2">
              <div className="col-lg-3 col-md-6">
                <Link to="/">
                  <img src="images/logo.png" alt="" loading="lazy" />
                </Link>
                <p className>
                  {" "}
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut
                </p>
              </div>
              <div className="col-lg-3 col-md-6 mt-lg-0 mt-3">
                <h2>About</h2>
                <ul>
                  <li>
                    <Link to="/About">About Us</Link>
                  </li>
                  <li>
                    <Link to="#">How it works</Link>
                  </li>
                  <li>
                    <Link to="/Contactus">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="#">Blog</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 mt-lg-0 mt-3">
                <h2>Service</h2>
                <ul>
                  <li>
                    <Link to="/allofcourses">Online Course</Link>
                  </li>
                  <li>
                    <Link to="#">Registered Yourself</Link>
                  </li>
                  <li>
                    <Link to="#">Get Certificate</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 mt-lg-0 mt-3">
                <h2>Terms</h2>
                <ul>
                  <li>
                    <Link to="#">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="#">Terms &amp; Conditions</Link>
                  </li>
                  <li>
                    <Link to="#">Copy Right Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 text-center px-0">
                <p>Copyright 2022. WrightCo. All Rights Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
                }
};

export default Footer;
