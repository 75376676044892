import React from "react";
import Header from "../Components/Header";

const About = () => {
  return (
    <>
      <section className="banner banner-padding">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="banner-heading">
                A Good Education Is a<br />
                Foundation For Better Future
              </h1>
              <p className="banner-p">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod
              </p>
              <a href="#_" className="general-btn d-inline-block">
                Get Started
              </a>
              <a href="#video">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={70}
                  height={70}
                  viewBox="0 0 70 70"
                >
                  <g
                    id="Group_10906"
                    data-name="Group 10906"
                    transform="translate(-845 -639)"
                  >
                    <circle
                      id="Ellipse_112"
                      data-name="Ellipse 112"
                      cx={35}
                      cy={35}
                      r={35}
                      transform="translate(845 639)"
                      fill="#00a79d"
                      opacity="0.5"
                    />
                    <path
                      id="Polygon_56"
                      data-name="Polygon 56"
                      d="M8.75,3.168a2,2,0,0,1,3.5,0l7.11,12.865A2,2,0,0,1,17.61,19H3.39a2,2,0,0,1-1.75-2.967Z"
                      transform="translate(893 664) rotate(90)"
                      fill="#fff"
                    />
                  </g>
                </svg>
                <h6>Watch Our Video</h6>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="video-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <video
                className="w-100"
                id="video"
                poster="images/video-poster.png"
                controls
              >
                <source src="images/mov_bbb.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </section>

      <section className="working">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="main-heading">
                Working Process For Join And <br />
                Benefits
              </h2>
              <p className="working-p">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam{" "}
              </p>
            </div>
          </div>
          <div className="row">
            <div
              className="col-lg-4 col-12 w-100 wow fadeInUp mt-lg-0 mt-2"
              data-wow-duration="3s"
            >
              <a href="#_">
                <div className="text-center icons-div">
                  <img src="images/search-icon.png" alt="" loading="lazy" />
                  <h4>Find Course</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut{" "}
                  </p>
                </div>
              </a>
            </div>
            <div
              className="col-lg-4 d-flex w-100 wow fadeInUp mt-lg-0 mt-2"
              data-wow-duration="3.2s"
            >
              <a href="#_">
                <div className="text-center icons-div">
                  <img src="images/calender.png" alt="" loading="lazy" />
                  <h4>Book Your Seat</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut{" "}
                  </p>
                </div>
              </a>
            </div>
            <div
              className="col-lg-4 d-flex w-100 wow fadeInUp mt-lg-0 mt-2"
              data-wow-duration="3.4s"
            >
              <a href="#_">
                <div className="text-center icons-div">
                  <img src="images/certificate.png" alt="" loading="lazy" />
                  <h4>Get Certificate</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut{" "}
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="learning">
        <div className="container-fluid">
          <div className="row">
            <div className="offset-xl-2 col-xl-3 position-relative text-xl-left text-center">
              <img
                src="images/learning-1.png"
                alt=""
                className="img-fluid learning-1"
              />
              <img src="images/learning-2.png" alt="" className="learning-2" />
            </div>
            <div className="col-xl-6 mt-xl-0 mt-3">
              <div className="learning-back">
                <img src="images/boxes.png" alt="" className="learning-boxes" />
                <h2 className="main-heading">
                  Learning Experiences Made Easy , Social And Interactive
                </h2>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua.{" "}
                </p>
                <div className="text-md-left text-center">
                  <a href="#_" className="general-btn d-inline-block ">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="explore explore-2">
        <div className="container">
          <div className="row wow fadeInDown" data-wow-duration="3s">
            <div className="col-12">
              <h2 className="main-heading">Explore Top Trending Blogs</h2>
              <h2 className="explore-sub">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod
              </h2>
            </div>
          </div>
          <div className="row">
            <div
              className="col-lg-4 mt-lg-0 mt-3 wow fadeInUp"
              data-wow-duration=".8s"
            >
              <a href="#_">
                <div className="explore-div position-relative">
                  <span>
                    <h5 className="f-20 medium lblack">23</h5>
                    <h6 className="f-14 medium lblack mt-1">Jul</h6>
                  </span>
                  <img
                    src="images/explore-1.png"
                    alt=""
                    className="img-fluid explore-main"
                    loading="lazy"
                  />
                  <div className="d-flex align-items-center name-div">
                    <img src="images/user-1.png" alt="" className="user-1" />
                    <div>
                      <h4>Thomas Smith</h4>
                      <h3>Article Writer</h3>
                    </div>
                  </div>
                  <p className="f-18 medium lblack">
                    How To Design a website to follow the rules of UI/UX...
                  </p>
                </div>
              </a>
            </div>
            <div
              className="col-lg-4 mt-lg-0 mt-3 wow fadeInUp"
              data-wow-duration=".8s"
            >
              <a href="#_">
                <div className="explore-div position-relative">
                  <span>
                    <h5 className="f-20 medium lblack">23</h5>
                    <h6 className="f-14 medium lblack mt-1">Jul</h6>
                  </span>
                  <img
                    src="images/explore-2.png"
                    alt=""
                    className="img-fluid explore-main"
                    loading="lazy"
                  />
                  <div className="d-flex align-items-center name-div">
                    <img src="images/user-1.png" alt="" className="user-1" />
                    <div>
                      <h4>Mark Carson</h4>
                      <h3>Content Writer</h3>
                    </div>
                  </div>
                  <p className="f-18 medium lblack">
                    How to work with Adobe Xd like a Pro.
                  </p>
                </div>
              </a>
            </div>
            <div
              className="col-lg-4 mt-lg-0 mt-3 wow fadeInUp"
              data-wow-duration=".8s"
            >
              <a href="#_">
                <div className="explore-div position-relative">
                  <span>
                    <h5 className="f-20 medium lblack">23</h5>
                    <h6 className="f-14 medium lblack mt-1">Jul</h6>
                  </span>
                  <img
                    src="images/explore-3.png"
                    alt=""
                    className="img-fluid explore-main"
                    loading="lazy"
                  />
                  <div className="d-flex align-items-center name-div">
                    <img src="images/user-1.png" alt="" className="user-1" />
                    <div>
                      <h4>Anderson Peter</h4>
                      <h3>Article Writer</h3>
                    </div>
                  </div>
                  <p className="f-18 medium lblack">
                    How to be a better learner , all tips &amp; tricks here.
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
      
    </>
  );
};

export default About;
