import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Banner from "../Components/Banner";
import { baseURL, imageURL } from "../utils/api";

const AllofCourses = () => {
  const [groupedCourses, setgroupedCourses] = useState([]);
  const [groupedcoursedata, setgroupedcoursedata] = useState([]);

  useEffect(() => {
    handleGetGroupedCourses();
  }, []);

  const handleGetGroupedCourses = async () => {
    try {
      const res = await axios({
        url: `${baseURL}/course/groupedCourses`,
        method: "GET",
      });
      console.log("res", res);
      setgroupedCourses(res?.data?.categoryCourses);
      setgroupedcoursedata(res?.data?.categoryCourses);

    } catch (err) {
      console.log(err);
    }
  };
  const categoryFilterHandler = async (id) => {
    try {
      const res = await axios({
        url: `${baseURL}/course/categoryfiltergroupedCourses/${id}`,
        method: "GET",
       
      });
      console.log("res", res);
      setgroupedCourses(res?.data?.categoryCourses);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
   <Banner title="Course"/>
      <section className="courses">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 text-left mb-5">
              <label className="filter-lbl">Filter:</label>
              <select name id className="genaral-select"  onChange={(e) => {
                              categoryFilterHandler(e.target.value);
                            }}
                          >
                            <option value>select</option>
                            {/* <option value>All</option> */}
                            {groupedcoursedata?.length > 0 &&
                              groupedcoursedata?.map((reg) => (
                                <option value={reg?.category?._id}>
                                  {reg?.category?.name}
                                </option>
                              ))}
              </select>
            </div>
          </div>
          {groupedCourses?.map((group) => (
            <>
              <div className="row">
                <div className="col-lg-6 mb-3">
                  <h2 className="course-heading"> {group?.category?.name}</h2>
                </div>
              </div>
              <div className="row course-row">
                <div className="col-12">
                  <div style={{ display: "flex" }}>
                    {group?.groupedata?.map((groupdata) => (
                      <div className="item" style={{ marginLeft: 15 }}>
                        <Link to={`/details-course${groupdata?._id}`}>
                          <img
                            src={
                              groupdata?.images?.length > 0
                                ? `${imageURL}${groupdata?.images?.[0]}`
                                : "images/course-3.png"
                            }
                            alt=""
                            className="course-thumbnail"
                          />
                          <h3 className="course-title">
                            {groupdata?.coursetitle}
                          </h3>
                          <p className="course-description">
                            {groupdata?.coursedescription}
                          </p>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </section>
    </>
  );
};

export default AllofCourses;
